import React, { useEffect, useMemo, useState } from 'react';
import './App.css';
import type { ChartData, ChartOptions } from 'chart.js';
import "chartjs-adapter-date-fns";
import { de } from 'date-fns/locale';
import { format } from 'date-fns';

import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  TimeSeriesScale,
} from 'chart.js';
import { Line } from 'react-chartjs-2';
import { Unit } from 'chart.js/dist/scales/scale.time';
import { setDefaultOptions } from 'date-fns';

import insideLogo from './house.png';
import outsideLogo from './park.png';

setDefaultOptions({ locale: de })

ChartJS.register(
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  Title,
  Tooltip,
  Legend,
  TimeScale,
  TimeSeriesScale
);

function getUnitForRange(range: Range): Unit {
  switch (range.unit) {
    case 'h': {
      if (range.value > 24) {
        return 'day'
      }
      return 'minute'
    }
    case 'd': {
      if (range.value > 4) {
        return 'day'
      }

      return 'minute'
    }
    default: {
      return 'day'
    }
  }
}

function getChartOptions(range: Range): ChartOptions<'line'> {
  return {
    responsive: true,
    scales: {
      x: {
        type: 'time',
        ticks: {
          major: {
            enabled: true,
          },
        },
        time: {
          tooltipFormat: 'HH:mm (dd.MMM yyyy)',
          minUnit: getUnitForRange(range),
          displayFormats: {
            minute: 'HH:mm',
            hour: 'HH:mm',
            day: 'dd.MMM',
            week: 'dd.MMM',
            quarter: 'dd.MMM.yyyy',
            year: 'dd.MMM.yyyy',
          },
        },
      },
    }
  };
}

const COLORS: { [key: string]: string } = {
  OUTSIDE: '#0000FF',
  INSIDE: '#FF0000',
};

async function getChartData(duration: string): Promise<ChartData<'line'>> {
  const result = await fetch(`/temperature?duration=${duration}&tag=INSIDE&tag=OUTSIDE&limit=250`, {
    headers: {
      Accept: 'application/json',
    },
  });
  const body = await result.json();
  const datasets = body.data.map(({ values, tag }: { values: any; tag: any }) => {
    return {
      label: tag,
      data: values.map((e: any) => ({ x: e.date, y: e.value })),
      borderColor: COLORS[tag],
    };
  });
  return { datasets }
}

type TemperatureTag = "INSIDE" | "OUTSIDE" | "WARM_WATER" | "HEATING_IN"

type TempLog = {
  timestamp: Date;
  tempValue: number;
  tag: TemperatureTag;
}

type Range = {
  unit: string,
  value: number,
}

async function fetchCurrent(): Promise<TempLog[]> {
  try {
    const res = await fetch("/temperature/current?tag=OUTSIDE&tag=INSIDE");
    const body: TempLog[] = await res.json()
    return body
  } catch (err) {
    console.error(err);
  }
  return [];
}

type CurrentTempState = {
  loading: boolean
  values: TempLog[]
}

function TagLabel({ tag }: { tag: TemperatureTag }) {
  switch (tag) {
    case 'INSIDE': return <img style={{ height: '28pt' }} src={insideLogo} />
    case 'OUTSIDE': return <img style={{ height: '28px' }} src={outsideLogo} />
    default: return <span>tag</span>
  }
}

function CurrentTemp() {
  const [state, setState] = useState<CurrentTempState>({
    loading: false,
    values: [],
  });

  useEffect(() => {
    setState(s => ({ ...s, loading: true }))
    fetchCurrent()
      .then(v => setState(s => ({ ...s, values: v, loading: false })))

    const interval = setInterval(async () => {
      setState(s => ({ ...s, loading: true }))
      fetchCurrent().then(v => setState(s => ({ ...s, values: v, loading: false })))
    }, 60000);

    return () => clearInterval(interval);
  }, []);

  return <>{state.loading ? <div>Loading...</div> : null}
    <table>
      <col />
      <col style={{ backgroundColor: '#ffaacc', width: '110px' }} />
      <col />
      <tbody>
        {state.values.map(entry => {
          return <tr key={entry.tag}>
            <td style={{ fontSize: 16, alignContent: 'left' }}><div><TagLabel tag={entry.tag} /></div></td>
            <td style={{ 'padding': '5px 15px 5px 15px', fontWeight: 'bold', fontSize: 28 }}>{entry.tempValue}°C</td>
            <td>[{format(entry.timestamp, 'HH:mm:ss (dd.MM.yyyy)')}]</td>
          </tr>
        })}
      </tbody>
    </table>
  </>
}

type State = {
  rangeValueInput: string,
  rangeUnitInput: string,
  range: Range,
  chartData: ChartData<'line'> | null,
  updateButtonPressed: number
}

function App() {
  const [state, setState] = useState<State>({
    rangeValueInput: '1',
    rangeUnitInput: 'd',
    range: { unit: 'd', value: 1 },
    chartData: null,
    updateButtonPressed: 0
  });
  // const [current, setCurrent] = useState<TempLog[]>([])

  // const [range, setRange] = useState<Range>({ unit: 'd', value: 1 })

  const chartRange = useMemo(() => {
    return state.range.value + state.range.unit;
  }, [state.range])

  const options = useMemo(() => {
    return getChartOptions(state.range)
  }, [state.range])

  useEffect(() => {
    getChartData(chartRange).then((data: ChartData<'line'>) => {
      setState(state => ({ ...state, chartData: data }));
    })
  }, [chartRange, state.updateButtonPressed])

  const onChangeDurationValue = (e: any) => {
    setState({
      ...state,
      rangeValueInput: e.target.value,
    })
  }

  const onChangeDurationUnit = (e: any) => {
    setState({
      ...state,
      rangeUnitInput: e.target.value
    })
  }

  const updateChartRange = () => {
    const value = parseInt(state.rangeValueInput, 10)
    setState({
      ...state,
      updateButtonPressed: state.updateButtonPressed < 100 ? state.updateButtonPressed + 1 : 0,
      range: { unit: state.rangeUnitInput, value },
      chartData: null
    })
  }

  return (
    <div className="App">
      <CurrentTemp />
      <div><span>Duration: </span><input type="number" value={state.rangeValueInput} onChange={onChangeDurationValue} /><span>
        <select value={state.rangeUnitInput} onChange={onChangeDurationUnit}>
          <option key="d" value="d">Days</option>
          <option key="h" value="h">Hours</option>
          <option key="y" value="y">Years</option>
        </select>
      </span>
        <div><button onClick={updateChartRange}>Update</button></div>
      </div>
      {state.chartData ? <Line redraw={true} updateMode={'reset'} options={options} data={state.chartData} /> : <div>Loading...</div>}
    </div>
  );
}

export default App;
